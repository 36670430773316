/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  @media screen and (max-device-width:768px){
    footer{
      font-size: calc(100vw * 5/414);
    }
  }